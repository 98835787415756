<template>
	<div class="page">
		<div class="title">智能双录授权协议</div>
		<div class="desc">
			<div class="txt">欢迎您使用智能双录服务！</div>
			<div class="txt">因应用提供者需要验证您的身份，<b>基于应用提供者的委托和授权</b>，杭州储信信息技术有限公司（简称“杭州储信”）通过人脸识别、录音录像等技术提供智能双录服务（简称“本服务”），以便于应用提供者更好地为您提供相关服务。</div>
			<div class="txt">您理解并同意，为了对您进行实人实名认证之目的，在使用本服务前，您须先授权应用提供者将其合法收集的相关个人信息（<b>您的姓名、身份证号码或您的照片</b>）传输给杭州储信，具体传输内容以您选择的本服务内容为准。在实人实名认证、录音录像的过程中，<b>杭州储信还会根据应用提供者的委托和指示，通过相机、麦克风、网络访问、网络状态、存储权限采集您的人脸信息和设备信息，将您的上述相关身份信息向包括“互联网+”可信身份认证平台及全国公民身份证号码查询服务中心在内的第三方核验机构传输</b>，以实现身份信息的比对核验，从而验证您的身份。根据应用提供者的要求以及您的授权，将您的<b>身份验证结果及身份信息传输返回至该应用提供者</b>。</div>
			<div class="txt"><b>人脸信息、身份信息属于敏感个人信息，且是杭州储信为应用提供者和您提供本服务所必需</b>。如应用提供者和您选择不提供或不同意杭州储信处理以上敏感个人信息的，将导致应用提供者和您无法正常使用本服务。</div>
			<div class="txt">获取设备信息是为识别和排除恶意请求、维护服务安全，用于检测终端设备的实时风险。<b>获取设备信息为本服务可选项，具体以您的应用提供者选择为准，应用提供者未选择该项的，不影响本服务流程的进行</b>。</div>
			<div class="txt"><b>点击我已阅读并同意或相关类似表述的按钮即开始采集您的个人信息</b>，亦即视为您知悉并同意应用提供者出于上述实人实名认证、录音录像目的、明示的处理方式、个人信息的类型，并在应用提供者委托杭州储信提供本服务期间内，授权杭州储信处理您的个人信息。杭州储信将严格按照应用提供者的委托和指示处理您的个人信息，本服务终止后杭州储信将删除您的个人信息、不予保留或将您的个人信息进行匿名化处理，除非杭州储信依照法律法规规定另行取得您的同意。</div>
			<div class="txt"><b>如您不同意授权，请不要点击我已阅读并同意按钮，同时您将无法继续使用本服务</b>，进而有可能影响您使用该应用提供者的相应服务，但这并不影响应用提供者通过其它实人实名认证、录音录像方式为您提供相关服务，法律法规另有规定的除外。如您使用本服务不成功，请确保您已经按照要求正确使用本服务，或者向应用提供者咨询其他办理渠道。</div>
			<div class="txt">杭州储信仅在为实现本服务<b>所必要的最短时间内</b>保存您的个人信息，杭州储信受托处理您的个人信息地点为<b>中华人民共和国境内</b>。杭州储信将采取法律法规规定的以及与应用提供者约定的必要安全措施，以尽到信息安全保障义务。<b>但您须知悉的是，本服务无法对应用提供者的信息安全保护能力或其处理行为做任何保证</b>。</div>
			<div class="txt">如您对应用提供者收集、提供、存储、使用您的个人信息有任何疑问，或决定行使法律法规规定的权利（如查阅、复制、撤回同意、修改、删除），您可以直接联系该应用提供者处理。如您发现您的个人信息权益被侵犯，可联系我们0571-87939597，杭州储信将在能力范围内尽快处理您的问题。</div>
			<div class="last">杭州储信信息技术有限公司</div>
		</div>
		
		<div class="bottomBox">
			<van-button class="btn" type="info" size="large" @click="jump('/')">我已阅读并同意</van-button>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		mounted() {
			
		},
		destroyed() {
			sessionStorage.setItem('from','policy')
		},
		methods:{
			jump(url){
				this.$router.push({
					path:url,
					query:{
						isCheck:true,
						id:this.$route.query.id,
						token:this.$route.query.token
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.page{
		padding: 24px;
	}
	.title{
		font-size: 18px;
		font-weight: bold;
		color: #333;
		margin-bottom: 16px;
		text-align: center;
	}
	.desc{
		font-size: 14px;
		line-height: 18px;
		color: #666;
		text-align: left;
		padding-bottom: 60px;
		div{
			margin-bottom: 10px;
		}
		.txt{
			text-indent: 2em;
		}
		.last{
			text-align: right;
		}
	}
	.bottomBox{
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		box-sizing: border-box;
		padding: 10px 24px 25px 24px;
		background-color: #fff;
		.btn{
			background-color: #5164FF;
			border-color: #5164FF;
			height: 44px;
			border-radius: 4px;
		}
		.txt{
			margin-top: 12px;
			color: #999;
			font-size: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			.radio{
				width: 24px;
				margin-right: 2px;
			}
			.rule{
				color: #5164FF;
			}
		}
	}
</style>